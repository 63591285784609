export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../../src/routes/__error.svelte"),
	() => import("../../src/routes/404.svelte"),
	() => import("../../src/routes/PrivacyPolicy.svelte"),
	() => import("../../src/routes/about.svelte"),
	() => import("../../src/routes/articles/[slug].svelte"),
	() => import("../../src/routes/articles/index.svelte"),
	() => import("../../src/routes/blog/[slug].svelte"),
	() => import("../../src/routes/blog/index.svelte"),
	() => import("../../src/routes/bokamera-ab.svelte"),
	() => import("../../src/routes/book-demo.svelte"),
	() => import("../../src/routes/brf.svelte"),
	() => import("../../src/routes/cancel-booking.svelte"),
	() => import("../../src/routes/categories/[slug].svelte"),
	() => import("../../src/routes/categories/index.svelte"),
	() => import("../../src/routes/code-lock.svelte"),
	() => import("../../src/routes/cookies.svelte"),
	() => import("../../src/routes/demo.svelte"),
	() => import("../../src/routes/embedded-signup.svelte"),
	() => import("../../src/routes/enterprise.svelte"),
	() => import("../../src/routes/faq.svelte"),
	() => import("../../src/routes/features/[slug].svelte"),
	() => import("../../src/routes/features/index.svelte"),
	() => import("../../src/routes/for-developers.svelte"),
	() => import("../../src/routes/gdpr.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/integrations.svelte"),
	() => import("../../src/routes/laundry.svelte"),
	() => import("../../src/routes/mobile-app.svelte"),
	() => import("../../src/routes/newsletter/unsubscribe/index.svelte"),
	() => import("../../src/routes/om-bokamera.svelte"),
	() => import("../../src/routes/passagesystem.svelte"),
	() => import("../../src/routes/payment.svelte"),
	() => import("../../src/routes/press.svelte"),
	() => import("../../src/routes/privacy.svelte"),
	() => import("../../src/routes/services.svelte"),
	() => import("../../src/routes/sign-up.svelte"),
	() => import("../../src/routes/speedledger.svelte"),
	() => import("../../src/routes/startup.svelte"),
	() => import("../../src/routes/terms-for-user-account.svelte"),
	() => import("../../src/routes/test-article.svelte"),
	() => import("../../src/routes/user-agreement.svelte"),
	() => import("../../src/routes/webbyra.svelte")
];

export const dictionary = {
	"": [[0, 25], [1]],
	"404": [[0, 2], [1]],
	"PrivacyPolicy": [[0, 3], [1]],
	"about": [[0, 4], [1]],
	"articles": [[0, 6], [1]],
	"blog": [[0, 8], [1]],
	"bokamera-ab": [[0, 9], [1]],
	"book-demo": [[0, 10], [1]],
	"brf": [[0, 11], [1]],
	"cancel-booking": [[0, 12], [1]],
	"categories": [[0, 14], [1]],
	"code-lock": [[0, 15], [1]],
	"cookies": [[0, 16], [1]],
	"demo": [[0, 17], [1]],
	"embedded-signup": [[0, 18], [1]],
	"enterprise": [[0, 19], [1]],
	"faq": [[0, 20], [1]],
	"features": [[0, 22], [1]],
	"for-developers": [[0, 23], [1]],
	"gdpr": [[0, 24], [1]],
	"integrations": [[0, 26], [1]],
	"laundry": [[0, 27], [1]],
	"mobile-app": [[0, 28], [1]],
	"om-bokamera": [[0, 30], [1]],
	"passagesystem": [[0, 31], [1]],
	"payment": [[0, 32], [1]],
	"press": [[0, 33], [1]],
	"privacy": [[0, 34], [1]],
	"services": [[0, 35], [1]],
	"sign-up": [[0, 36], [1]],
	"speedledger": [[0, 37], [1]],
	"startup": [[0, 38], [1]],
	"terms-for-user-account": [[0, 39], [1]],
	"test-article": [[0, 40], [1]],
	"user-agreement": [[0, 41], [1]],
	"webbyra": [[0, 42], [1]],
	"newsletter/unsubscribe": [[0, 29], [1]],
	"articles/[slug]": [[0, 5], [1]],
	"blog/[slug]": [[0, 7], [1]],
	"categories/[slug]": [[0, 13], [1]],
	"features/[slug]": [[0, 21], [1]]
};